import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

class Card extends Component {
    render() {

        return (
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">{ ReactHtmlParser (this.props.title) }</h6>
                </div>
                <div className="card-body">
                    {this.props.children}
                </div>
            </div>

        );
    }
}

export default Card;

class CollasableCard extends Component {

    render() {
        let cardId = this.props.title.replace(/\s/g, '') +"Card";

        return (
            <div className="card shadow mb-4">
                <a href={"#" + cardId} className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls={cardId}>
                    <h6 className="m-0 font-weight-bold text-primary">{this.props.title}</h6>
                </a>
                <div className="collapse show" id={cardId}>
                    <div className="card-body">
                        {this.props.children}
                    </div>
                </div>

            </div>
        );
    }
}

export {
    Card,
    CollasableCard
};