import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Converters from './Converters';
import InputField from './InputField';
import Card, { CollasableCard } from './Cards';

class Refractometer extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();

        let ret1 = this.calcGravity(
            cookies.get('refractometer.originalBrixGravity') || 12,
            cookies.get('refractometer.correctionFactor') || 1.04
        );

        let ret2 = this.calcFinalGravity(
            cookies.get('refractometer.originalBrixGravity2') || 12,
            cookies.get('refractometer.correctionFactor2') || 1.04,
            cookies.get('refractometer.finalBrixGravity') || 5
        );
        let merged = { ...ret1, ...ret2 };
        this.state = merged;

    }
    componentDidMount() {
        document.title = "Kalkulator piwowara - Korekcja wskazań refraktometru";
    }
    getFGFromBrix(brixOg, brixFg) {
        return 1.0000 - 0.0044993 * brixOg + 0.011774 * brixFg +
            0.00027581 * Math.pow(brixOg, 2) - 0.0012717 * Math.pow(brixFg, 2)
            - 0.0000072800 * Math.pow(brixOg, 3) + 0.000063293 * Math.pow(brixFg, 3);
    }
    calcGravity(originalBrixGravity, correctionFactor) {

        let originalBLGGravity;
        if (originalBrixGravity > 0 && correctionFactor > 0) {
            const blg = originalBrixGravity / correctionFactor;

            originalBLGGravity = blg.toFixed(2);

        } else {
            originalBLGGravity = 'NaN';
        }

        return {
            originalBrixGravity: originalBrixGravity,
            correctionFactor: correctionFactor,
            originalBLGGravity: originalBLGGravity,
        }
    }
    calcFinalGravity(originalBrixGravity2, correctionFactor2, finalBrixGravity) {
        let finalBLGGravity;
        if (originalBrixGravity2 > 0 && correctionFactor2 > 0 && finalBrixGravity > 0) {
            const blg = originalBrixGravity2 / correctionFactor2;
            const fg = finalBrixGravity / correctionFactor2;

            const FGinSG = this.getFGFromBrix(blg, fg);
            const trueFG = Converters.gravityToPlato(FGinSG);

            finalBLGGravity = trueFG.toFixed(2);
        } else {
            finalBLGGravity = 'NaN';
        }
        return {
            originalBrixGravity2: originalBrixGravity2,
            correctionFactor2: correctionFactor2,
            finalBrixGravity: finalBrixGravity,
            finalBLGGravity: finalBLGGravity
        }
    }
    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const cookies = new Cookies();
        let ret;
        if (name === 'originalBrixGravity') {
            cookies.set('refractometer.originalBrixGravity', value);
            ret = this.calcGravity(value, this.state.correctionFactor);
        } else {
            cookies.set('refractometer.correctionFactor', value);
            ret = this.calcGravity(this.state.originalBrixGravity, value);
        }

        this.setState(ret);
    }
    handleOnChangeFinal(event) {
        const name = event.target.name;
        const value = event.target.value;
        const cookies = new Cookies();
        let ret;
        if (name === 'originalBrixGravity2') {
            cookies.set('refractometer.originalBrixGravity2', value);
            ret = this.calcFinalGravity(value, this.state.correctionFactor2, this.state.finalBrixGravity);
        } else if (name === 'correctionFactor2') {
            cookies.set('refractometer.correctionFactor2', value);
            ret = this.calcFinalGravity(this.state.originalBrixGravity2, value, this.state.finalBrixGravity);
        } else {
            cookies.set('refractometer.finalBrixGravity', value);
            ret = this.calcFinalGravity(this.state.originalBrixGravity2, this.state.correctionFactor2, value);
        }

        this.setState(ret);
    }
    render() {
        return (
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Korekcja wskazań refraktometru">
                            <p>
                                Konwertuje odczyty pobranej brzeczki wykonane za pomocą refraktometru do ich rzeczywistej wartości w BLG/Plato. Umożliwa
                                również korekcję odczytów refraktometrem końcowej gęstości piwa (wymaga znajomości gęstości początkowej).
                            </p>
                        </CollasableCard>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <Card title="Korekcja wskazań refraktometru dla brzeczki">
                            <InputField name="originalBrixGravity" label="Gęstość początkowa" step="0.1" value={this.state.originalBrixGravity} unit="Brix" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="correctionFactor" label="Współczynnik korekcji" step="0.01" value={this.state.correctionFactor} onChange={(event) => this.handleOnChange(event)} />
                            <InputField label="Gęstość początkowa (skorygowana)" value={this.state.originalBLGGravity} unit="BLG" readOnly />
                        </Card>
                    </div>

                    <div className="col-sm-6">
                        <Card title="Korekcja wskazań refraktometru dla gotowego piwa">
                            <InputField name="originalBrixGravity2" label="Gęstość początkowa" step="0.1" value={this.state.originalBrixGravity2} unit="Brix" onChange={(event) => this.handleOnChangeFinal(event)} />
                            <InputField name="finalBrixGravity" label="Gęstość końcowa" step="0.1" value={this.state.finalBrixGravity} unit="Brix" onChange={(event) => this.handleOnChangeFinal(event)} />
                            <InputField name="correctionFactor2" label="Współczynnik korekcji" step="0.01" value={this.state.correctionFactor2} onChange={(event) => this.handleOnChangeFinal(event)} />
                            <InputField label="Gęstość końcowa (skorygowana)" value={this.state.finalBLGGravity} unit="BLG" readOnly />
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
};

export default Refractometer;