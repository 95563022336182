import React, { Component } from 'react';
import Card from './Cards';


class Home extends Component {
    render() {
        let logoStyle = {
            fontSize: '2rem'
        };
        return (
            <div className="col-sm-12">
                <Card title="Witaj w Kalkulatorze Piwowara">
                    <p>
                        Strona ta jest zbiorem narzędzi niezbędnych każdemu piwowarowi domowemu.<br /> Dzięki nam zaprojektujesz a następnie uwarzysz pyszne piwo.
                        Zdrówko

                    </p>
                    <div className="d-flex align-items-center justify-content-center" style={logoStyle}>
                        <div className="rotate-15 ">
                            <i className="fas fa-beer fa-flip-horizontal"></i>
                        </div>
                        <div className="rotate-n-15">
                            <i className="fas fa-beer"></i>
                        </div>
                    </div>

                </Card>
            </div>
        );
    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara";
    }
}

export default Home;