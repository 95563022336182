import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import InputField from './InputField';
import CollasableCard from './Cards';
import MaltInput from './MaltInput';
import HoverButton from './HoverButton';
import ColorIndicator from './ColorIndicator';
import HopInput from './HopInput';

const slody = [
    {
        'Id': 0,
        'Name': 'Pilzneński',
        'Weight': 1.5,
        'Extract': 82.6,
        'Color': 4
    },
    {
        'Id': 1,
        'Name': 'Pszeniczny',
        'Weight': 2.5,
        'Extract': 82.2,
        'Color': 4
    },
    {
        'Id': 2,
        'Name': 'Karmelowy jasny',
        'Weight': 0.25,
        'Extract': 75.9,
        'Color': 25
    }
]

const chmiele = [
    {
        'Id': 0,
        'Name': 'Chmiel 1',
        'Weight': 10,
        'Acid': 8.5,
        'Time': 60
    },
    {
        'Id': 1,
        'Name': 'Chmiel 2',
        'Weight': 10,
        'Acid': 8.5,
        'Time': 30
    },
    {
        'Id': 2,
        'Name': 'Chmiel 3',
        'Weight': 30,
        'Acid': 3.8,
        'Time': 15
    }
]

class Recipe extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();

        let maltMaxId = Math.max.apply(Math, slody.map((o) => o.Id));
        let hopMaxId = Math.max.apply(Math, chmiele.map((o) => o.Id));
        this.state = {
            hotWorthVolume: cookies.get('recipe.hotWorthVolume') || 24.0,
            recipeEfficiency: cookies.get('recipe.recipeEfficiency') || 80.0,
            maltId: maltMaxId,
            malts: slody,
            hopId: hopMaxId,
            hops: chmiele,
        };

        this.appendMalt = this.appendMalt.bind(this);
        this.removeMalt = this.removeMalt.bind(this);
        this.appendHop = this.appendHop.bind(this);
        this.removeHop = this.removeHop.bind(this);
    }

    componentDidMount() {
        document.title = "Kalkulator piwowara - Projektowanie przepisu";
    }

    handleOnChange(event) {
        const { target: { name, value } } = event;
        const cookies = new Cookies();
        let newState = this.state;
        if (name === 'hotWorthVolume') {
            cookies.set('recipe.hotWorthVolume', value);
        }
        if (name === 'recipeEfficiency') {
            cookies.set('recipe.recipeEfficiency', value);
        }
        newState[name] = value;

        this.setState(newState);
    }

    handleInputChange(event, Id) {
        const { target: { name, value } } = event;
        var newState = this.state;
        var foundIndex = newState.malts.findIndex(x => x.Id === Id);
        newState.malts[foundIndex][name] = value;
        this.setState(newState);
    }

    handleHopChange(event, Id) {
        const { target: { name, value } } = event;
        var newState = this.state;
        var foundIndex = newState.hops.findIndex(x => x.Id === Id);
        newState.hops[foundIndex][name] = value;
        this.setState(newState);
    }

    appendMalt() {
        this.setState({
            malts: [
                ...this.state.malts,
                {
                    'Id': this.state.maltId + 1,
                    'Name': 'Slod',
                    'Weight': 0,
                    'Extract': 80,
                    'Color': 7
                }
            ],
            maltId: this.state.maltId + 1
        });
    }

    removeMalt(Id) {
        const newState = this.state;
        const index = newState.malts.findIndex(a => a.Id === Id);

        if (index === -1) {
            return;
        }
        newState.malts.splice(index, 1);
        this.setState(newState);
    }

    appendHop() {
        this.setState({
            hops: [
                ...this.state.hops,
                {
                    'Id': this.state.hopId + 1,
                    'Name': 'Chmiel',
                    'Weight': 0,
                    'Acid': 5,
                    'Time': 60
                }
            ],
            hopId: this.state.hopId + 1
        });
    }

    removeHop(Id) {
        const newState = this.state;
        const index = newState.hops.findIndex(a => a.Id === Id);

        if (index === -1) {
            return;
        }
        newState.hops.splice(index, 1);
        this.setState(newState);
    }

    calcMaltsWeight(malts) {
        const maltsWeight = malts.reduce((a, b) => a + parseFloat(b.Weight), 0);
        return maltsWeight;
    }

    calcBlg(malts, efficiency, volume) {
        const realExtract = malts.reduce((a, b) => a + b.Weight * b.Extract * efficiency / 10.0, 0);
        const realExtract_ml = realExtract / 1.587;
        const water_ml = volume * 1000 - realExtract_ml;
        const worthWeight = water_ml + realExtract;
        const blg = 100 * realExtract / worthWeight;
        return blg;
    }

    calcEBC(malts, volume)
    {
        const colorSum = malts.reduce((a,b) => a + (b.Weight * 2.20462262184878)*(b.Color / 1.97), 0);
        const colorSumVolume = colorSum / (volume / 3.78541178);
        const colorSumPow = Math.pow(colorSumVolume, 0.6859);
        let ebc = colorSumPow * 1.4922 * 1.97;
        if (ebc > 80) {
            ebc = 80;
        }
        return ebc;
    }


    calcEBC2RGB(ebc) {
        let srm = ebc * 0.508;
        
        const colorLUT = [
            {srm:1, r:255, g:230, b:153},
            {srm:2, r:255, g:216, b:120},
            {srm:3, r:255, g:202, b:90},
            {srm:4, r:255, g:191, b:66},
            {srm:5, r:251, g:177, b:35},
            {srm:6, r:248, g:166, b:0},
            {srm:7, r:243, g:156, b:0},
            {srm:8, r:234, g:143, b:0},
            {srm:9, r:229, g:133, b:0},
            {srm:10, r:222, g:124, b:0},
            {srm:11, r:215, g:114, b:0},
            {srm:12, r:207, g:105, b:0},
            {srm:13, r:203, g:98, b:0},
            {srm:14, r:195, g:89, b:0},
            {srm:15, r:187, g:81, b:0},
            {srm:16, r:181, g:76, b:0},
            {srm:17, r:176, g:69, b:0},
            {srm:18, r:166, g:62, b:0},
            {srm:19, r:161, g:55, b:0},
            {srm:20, r:155, g:50, b:0},
            {srm:21, r:149, g:45, b:0},
            {srm:22, r:142, g:41, b:0},
            {srm:23, r:136, g:35, b:0},
            {srm:24, r:130, g:30, b:0},
            {srm:25, r:123, g:26, b:0},
            {srm:26, r:119, g:25, b:0},
            {srm:27, r:112, g:20, b:0},
            {srm:28, r:106, g:14, b:0},
            {srm:29, r:102, g:13, b:0},
            {srm:30, r:94, g:11, b:0},
            {srm:31, r:90, g:10, b:2},
            {srm:32, r:96, g:9, b:3},
            {srm:33, r:82, g:9, b:7},
            {srm:34, r:76, g:5, b:5},
            {srm:35, r:71, g:6, b:6},
            {srm:36, r:68, g:6, b:7},
            {srm:37, r:63, g:7, b:8},
            {srm:38, r:59, g:6, b:7},
            {srm:39, r:58, g:7, b:11},
            {srm:40, r:54, g:8, b:10},
            {srm:41, r:20, g:4, b:2}
        ];

        var srmInt = Math.round(srm);
        const index = colorLUT.findIndex(c => c.srm === srmInt);
        let r = 255,g = 255,b = 255;
        if (index !== -1) {
            r = colorLUT[index].r;
            g = colorLUT[index].g;
            b = colorLUT[index].b;
        } 

        return r + "," + g + "," + b;
    }

    calcUtilization(time) {
        
        const Util = [            
            {t:0, v:5.00},
            {t:1, v:5.11},
            {t:2, v:5.22},
            {t:3, v:5.33},
            {t:4, v:5.44},
            {t:5, v:5.56},
            {t:6, v:5.67},
            {t:7, v:5.78},
            {t:8, v:5.89},
            {t:9, v:6.00},
            {t:10, v:7.50},
            {t:11, v:9.00},
            {t:12, v:10.50},
            {t:13, v:12.00},
            {t:14, v:13.50},
            {t:15, v:15.00},
            {t:16, v:15.40},
            {t:17, v:15.80},
            {t:18, v:16.20},
            {t:19, v:16.60},
            {t:20, v:17.00},
            {t:21, v:17.40},
            {t:22, v:17.80},
            {t:23, v:18.20},
            {t:24, v:18.60},
            {t:25, v:19.00},
            {t:26, v:19.42},
            {t:27, v:19.83},
            {t:28, v:20.25},
            {t:29, v:20.67},
            {t:30, v:21.08},
            {t:31, v:21.50},
            {t:32, v:21.92},
            {t:33, v:22.33},
            {t:34, v:22.75},
            {t:35, v:23.17},
            {t:36, v:23.58},
            {t:37, v:24.00},
            {t:38, v:24.20},
            {t:39, v:24.40},
            {t:40, v:24.60},
            {t:41, v:24.80},
            {t:42, v:25.00},
            {t:43, v:25.20},
            {t:44, v:25.40},
            {t:45, v:25.60},
            {t:46, v:25.80},
            {t:47, v:26.00},
            {t:48, v:26.20},
            {t:49, v:26.40},
            {t:50, v:26.60},
            {t:51, v:26.80},
            {t:52, v:27.00},
            {t:53, v:27.38},
            {t:54, v:27.75},
            {t:55, v:28.13},
            {t:56, v:28.50},
            {t:57, v:28.88},
            {t:58, v:29.25},
            {t:59, v:29.63},
            {t:60, v:30.00},
            {t:61, v:30.27},
            {t:62, v:30.53},
            {t:63, v:30.80},
            {t:64, v:31.07},
            {t:65, v:31.33},
            {t:66, v:31.60},
            {t:67, v:31.87},
            {t:68, v:32.13},
            {t:69, v:32.40},
            {t:70, v:32.67},
            {t:71, v:32.93},
            {t:72, v:33.20},
            {t:73, v:33.47},
            {t:74, v:33.73},
            {t:75, v:34.00}
        ];

        time = Math.round(time);
        if (time < 0) {
            time = 0;
        }
        if (time > 75) {
            time = 75;
        }
        const index = Util.findIndex(c => c.t === time);
        let util = 5;
        if (index !== -1) {
            util = Util[index].v;
        } 
        return util;
    }

    calcIBU(weight, acid, time, hotWorthVolume) {
        return weight * acid * this.calcUtilization(time) / (hotWorthVolume * 10);
    }

    render() {

        const maltsWeight = this.calcMaltsWeight(this.state.malts);
        const blg = this.calcBlg(this.state.malts, this.state.recipeEfficiency, this.state.hotWorthVolume);
        const ebc = this.calcEBC(this.state.malts, this.state.hotWorthVolume);
        const ibu = this.state.hops.reduce((a, b) => a + this.calcIBU(b.Weight, b.Acid, b.Time, this.state.hotWorthVolume), 0);
        const ibu_to_blg = ibu / blg;
        let bitternessDescription = "";

        if (ibu_to_blg < 1.5) {
            bitternessDescription = "Goryczka niewyczuwalna lub wyczuwalna w niewielkim stopniu";
        } else if (ibu_to_blg < 3) {
            bitternessDescription = "Goryczka na poziomie średnim zrównoważona zawartością słodu";
        } else if (ibu_to_blg < 4) {
            bitternessDescription = "Goryczka na poziomie wysokim";
        } else {
            bitternessDescription = "Goryczka na poziomie bardzo wysokim";
        }

        const malts = this.state.malts.map(malt => {
            return <MaltInput 
                key={malt.Id}
                Id={malt.Id}
                Name={malt.Name}
                Weight={malt.Weight}
                Extract={malt.Extract}
                Color={malt.Color}
                Content={(100 * malt.Weight / maltsWeight).toFixed(1)}
                removeMalt={this.removeMalt.bind(this)}
                handleInputChange={this.handleInputChange.bind(this)}
            />
        });

        const hops = this.state.hops.map(hop => {
            return <HopInput 
                key={hop.Id}
                Id={hop.Id}
                Name={hop.Name}
                Weight={hop.Weight}
                Acid={hop.Acid}
                Time={hop.Time}
                IBU={this.calcIBU(hop.Weight, hop.Acid, hop.Time, this.state.hotWorthVolume).toFixed(1)}
                removeHop={this.removeHop.bind(this)}
                handleInputChange={this.handleHopChange.bind(this)}
            />
        });

        return (
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Parametry brzeczki">
                            <InputField name="hotWorthVolume" label="Planowana ilość gorącej brzeczki" step="0.1" value={this.state.hotWorthVolume} unit="L" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="recipeEfficiency" label="Wydajność zacieru" step="1" value={this.state.recipeEfficiency} unit="%" onChange={(event) => this.handleOnChange(event)} />
                        </CollasableCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Słody">
                            <div className="row">
                                <div className="col-sm-2">Nazwa słodu</div>
                                <div className="col-sm-2">Waga</div>
                                <div className="col-sm-2">% ekstraktu</div>
                                <div className="col-sm-2">Barwa EBC</div>
                                <div className="col-sm-2">Udział procentowy</div>
                            </div>
                            {malts}
                            <HoverButton onClick={this.appendMalt} text="Dodaj słód" hoveredIcon="fa-plus-circle" normalIcon="fa-plus" />
                        </CollasableCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Chmiele">
                            <div className="row">
                                <div className="col-sm-2">Nazwa chmielu</div>
                                <div className="col-sm-2">Waga</div>
                                <div className="col-sm-2">α-kwasy</div>
                                <div className="col-sm-2">Czas gotowania</div>
                                <div className="col-sm-2">IBU</div>
                            </div>
                            {hops}
                            <HoverButton onClick={this.appendHop} text="Dodaj Chmiel" hoveredIcon="fa-plus-circle" normalIcon="fa-plus" />
                        </CollasableCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Parametry uzyskanej brzeczki">

                            <div className="row">
                                <div className="col-sm-6">
                                    <InputField name="blg" label="Ekstrakt uzyskanego zacieru" value={blg.toFixed(1)} unit="%" readOnly={true} />
                                </div>
                                <div className="col-sm-6">
                                    <InputField name="ibu" label="Goryczkowość piwa" value={ibu.toFixed(1)} unit="IBU" readOnly={true} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <InputField name="ebc" label="Kolor brzeczki" value={ebc.toFixed(0)} unit="EBC" readOnly={true} />
                                </div>
                                <div className="col-sm-6">
                                    <InputField name="ibu" label="Stosunek IBU / ekstraktu" value={ibu_to_blg.toFixed(1)} readOnly={true} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label className="control-label font-weight-bold">Orientacyjny kolor piwa</label>
                                    <ColorIndicator color={this.calcEBC2RGB(ebc)} />
                                </div>
                                <div className="col-sm-6">
                                    <label className="control-label font-weight-bold">Opisowe przedstawienie poziomu substancji goryczkowych</label>
                                    <label className="control-label">{bitternessDescription}</label>
                                </div>
                            </div>
                        </CollasableCard>
                    </div>
                </div>
            </div>
        )
    }
}

export default Recipe;