import React, { Component } from 'react';

import InputField from './InputField';
import Card from './Cards';

class Temperature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            celcius: 20,
            farenheit: 68,
            kelvin: 293.15,
        };

    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara - Konwerter jednostek tempertury";
    }
    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const coeffs = {
            celcius: [1.0, 0],
            farenheit: [1.8, 32],
            kelvin: [1, 273.15],
        };

        const C = (value - coeffs[name][1]) / coeffs[name][0];
        const F = C * coeffs['farenheit'][0] + coeffs['farenheit'][1];
        const K = C * coeffs['kelvin'][0] + coeffs['kelvin'][1];

        let ret = {
            celcius: C.toFixed(2),
            farenheit: F.toFixed(2),
            kelvin: K.toFixed(2),
        };

        delete ret[name];
        ret[name] = value;

        this.setState(ret);
    }
    render() {
        return (
            <div className="col-sm-6">
                <Card title="Konwerter jednostek temperatury">
                    <InputField name="celcius" label="Celsjusz" step="0.1" value={this.state.celcius} unit="°C" onChange={(event) => this.handleOnChange(event)} />
                    <InputField name="farenheit" label="Farenheit" step="0.1" value={this.state.farenheit} unit="°F" onChange={(event) => this.handleOnChange(event)} />
                    <InputField name="kelvin" label="Kelvin" step="0.1" value={this.state.kelvin} unit="K" onChange={(event) => this.handleOnChange(event)} />
                </Card>
            </div>
        );
    }
};

export default Temperature;