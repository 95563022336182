import React, { Component } from 'react';
import HoverButton from './HoverButton';
import InputField from './InputField';


class HopInput extends Component {

    render() {

        return (
            <div className="row">
                <div className="col-sm-2 form-group">
                    <input onChange={(e) => this.props.handleInputChange(e, this.props.Id)} name="Name" className="form-control" type="text" value={this.props.Name}></input>
                </div>
                <div className="col-sm-2 form-group">
                    <InputField unit="g" name="Weight" value={this.props.Weight} step="1" onChange={(e) => this.props.handleInputChange(e, this.props.Id)} />
                </div>
                <div className="col-sm-2 form-group">
                    <InputField unit="%" name="Acid" value={this.props.Acid} onChange={(e) => this.props.handleInputChange(e, this.props.Id)} />
                </div>
                <div className="col-sm-2 form-group">
                    <InputField unit="min" name="Time" value={this.props.Time} step="1" onChange={(e) => this.props.handleInputChange(e, this.props.Id)} />
                </div>
                <div className="col-sm-2 form-group">
                    <InputField value={this.props.IBU} readOnly={true}/>
                </div>
                <div className="col-sm-2">
                    <HoverButton onClick={() => this.props.removeHop(this.props.Id)} text="Usuń" btnClass="btn-danger" hoveredIcon="fa-minus-circle" normalIcon="fa-minus"/>
                </div>
            </div>
        );
    }
}

export default HopInput;