import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

class InputField extends Component {
    render() {

        let unit;
        if (this.props.unit) {
            unit = (
                <div className="input-group-append">
                    <span className="input-group-text">{this.props.unit}</span>
                </div>
            );
        }

        let label;
        if (this.props.label) {
            label = (
                <label className="control-label font-weight-bold">{ ReactHtmlParser (this.props.label) }</label>
            );
        }

        return (
            <div className="form-group">
                {label}
                <div className="input-group">
                    <input className="form-control" type="number" lang="en" step={this.props.step || 0.1} name={this.props.name} value={this.props.value} onChange={this.props.onChange} readOnly={this.props.readOnly}></input>
                    {unit}
                </div>
            </div>
        );
    }
}

export default InputField;