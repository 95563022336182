import React, { Component } from 'react';
import AdSense from 'react-adsense';
import withTracker from './withTracker';
import CookieConsent from "react-cookie-consent";

import 'startbootstrap-sb-admin-2/css/sb-admin-2.min.css';
import 'startbootstrap-sb-admin-2/vendor/fontawesome-free/css/all.min.css';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom';

import Home from './Home';
import Contact from "./Contact";
import Carbonation from "./Carbonation";
import Alcohol from "./Alcohol";
import Dilution from "./Dilution";
import Refractometer from "./Refractometer";
import Infusion from "./Infusion";
import Temperature from './Temperature';
import Weight from './Weight';
import Volume from './Volume';
import Gravity from './Gravity';
import Recipe from './Recipe';

class App extends Component {

  render() {

    return (
      <Router>
        {/* Page Wrapper */}
        <div id="wrapper">
          {/* Sidebar */}
          <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" >
            {/* Sidebar - Brand */}
            <Link to="/" className="sidebar-brand d-flex align-items-center justify-content-center">
              <div className="sidebar-brand-icon rotate-15 ">
                <i className="fas fa-beer fa-flip-horizontal"></i>
              </div>
              <div className="sidebar-brand-icon rotate-n-15">
                <i className="fas fa-beer"></i>
              </div>
              <div className="sidebar-brand-text mx-3">Kalkulator Piwowara</div>
            </Link>

            {/* Divider */}
            <hr className="sidebar-divider my-0"></hr>

            <li className="nav-item">
              <Link to="/recipe" className="nav-link collapsed">
                <i className="fas fa-fw fa-calculator"></i>
                <span>Receptura</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="#" className="nav-link collapsed" data-toggle="collapse" data-target="#collapseCalc" aria-expanded="true" aria-controls="collapseCalc">
                  <i className="fa fa-calculator "></i> 
                  <span>Kalkulatory</span>
              </Link>

              <div id="collapseCalc" className="collapse" aria-labelledby="headingConv" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link className="collapse-item" to="/carbonation">Nagazowanie</Link>
                  <Link className="collapse-item" to="/dilution">Korekta gęstości</Link>
                  <Link className="collapse-item" to="/alcohol">Alkohol</Link>
                  <Link className="collapse-item" to="/refractometer">Korekcja refraktometru</Link>
                  <Link className="collapse-item" to="/infusion">Infuzja</Link>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <Link to="#" className="nav-link collapsed" data-toggle="collapse" data-target="#collapseConv" aria-expanded="true" aria-controls="collapseConv">
                <i className="fas fa-fw fa-utensil-spoon"></i>
                <span>Przeliczniki</span>
              </Link>

              <div id="collapseConv" className="collapse" aria-labelledby="headingConv" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link className="collapse-item" to="/weight">Waga</Link>
                  <Link className="collapse-item" to="/temperature">Temperatura</Link>
                  <Link className="collapse-item" to="/volume">Objętość</Link>
                  <Link className="collapse-item" to="/gravity">Gęstość</Link>
                </div>
              </div>
            </li>

            {/* Divider */}
            <hr className="sidebar-divider my-0"></hr>

            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                <i className="fas fa-fw fa-envelope-open"></i>
                <span>Kontakt</span>
              </Link>
            </li>

            {/* Divider */}
            <hr className="sidebar-divider d-none d-md-block" />

            {/* Sidebar Toggler (Sidebar) */}
            <div className="text-center d-none d-md-inline">
              <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>
          </ul>

          <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="cookieLawSeen"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            <p style={{ fontSize: '14px' }}>Ważne: Strona wykorzystuje pliki cookies.</p>
            <p style={{ fontSize: '12px' }}> W ramach naszej witryny stosujemy pliki cookies w celu świadczenia Państwu usług na najwyższym poziomie. Korzystanie
              z witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w Państwa urządzeniu końcowym.
              Możecie Państwo dokonać w każdym czasie zmiany ustawień dotyczących cookies.
            </p>
          </CookieConsent>

          <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

              {/* Topbar */}
              <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top-shadow">
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars"></i>
                </button>
              </nav>

              {/* Begin Page Content */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 center">
                    <AdSense.Google
                      client='ca-pub-4891795862700093'
                      slot='2021917566'
                      style={{ display: 'block' }}
                      format='auto'
                      responsive='true'
                    />
                  </div>
                </div>
                <div className="row">
                  <Switch>
                    <Route exact path="/" component={withTracker(Home)} />
                    <Route exact path="/contact" component={withTracker(Contact)} />
                    <Route exact path="/carbonation" component={withTracker(Carbonation)} />
                    <Route exact path="/alcohol" component={withTracker(Alcohol)} />
                    <Route exact path="/dilution" component={withTracker(Dilution)} />
                    <Route exact path="/refractometer" component={withTracker(Refractometer)} />
                    <Route exact path="/infusion" component={withTracker(Infusion)} />
                    <Route exact path="/temperature" component={withTracker(Temperature)} />
                    <Route exact path="/weight" component={withTracker(Weight)} />
                    <Route exact path="/volume" component={withTracker(Volume)} />
                    <Route exact path="/gravity" component={withTracker(Gravity)} />
                    <Route exact path="/recipe" component={withTracker(Recipe)} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
