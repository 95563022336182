import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import InputField from './InputField';
import Card, { CollasableCard } from './Cards';

class Infusion extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();

        let ret = this.calculateInfusion(
            cookies.get('infusion.grainWeight') || 4.5,
            cookies.get('infusion.grainTemp') || 18,
            cookies.get('infusion.waterGrainRatio') || 2.5,
            cookies.get('infusion.restTemp1') || 68.0,
            cookies.get('infusion.restTemp2') || 0,
            cookies.get('infusion.restTemp3') || 0,
            cookies.get('infusion.restTemp4') || 0,
            cookies.get('infusion.restTemp5') || 0
        );

        this.state = ret;

    }

    componentDidMount() {
        document.title = "Kalkulator Piwowara - Kalkulator wody do infuzji";
    }

    calculateInfusion(grainWeight, grainTemp, waterGrainRatio, restTemp1, restTemp2, restTemp3, restTemp4, restTemp5) {
        // From Northern Brewer ~0.38 but Jon Palmer suggest 0.41
        // to compensate for the lost to the tun even if the tun is pre-heaten
        const specificHeatBarley = 0.41;
        const infusionTemp = 100;

        let waterTemp1 = (specificHeatBarley / waterGrainRatio) * (restTemp1 - grainTemp) + parseFloat(restTemp1);
        waterTemp1 = parseFloat(waterTemp1).toFixed(1);

        let waterVolume1 = (waterGrainRatio * grainWeight);
        var totalVolume = waterVolume1;
        waterVolume1 = waterVolume1.toFixed(1);

        for (let i = 2; i <= 5; i++) {
            this['waterVolume' + i] = "-";
        }

        for (let i = 2; i <= 5; i++) {
            var stepTemp = arguments[2 + i];
            var lastStepTemp = arguments[1 + i];

            if (stepTemp < infusionTemp && stepTemp > lastStepTemp) {


                var addBoiling = ((stepTemp - lastStepTemp) * ((specificHeatBarley * grainWeight) + totalVolume)) / (infusionTemp - stepTemp);
                totalVolume += addBoiling;
                this['waterVolume' + i] = addBoiling.toFixed(1);
            } else {
                break;
            }
        }

        return ({
            grainWeight: grainWeight,
            grainTemp: grainTemp,
            waterGrainRatio: waterGrainRatio,
            restTemp1: restTemp1,
            waterVolume1: waterVolume1,
            waterTemp1: waterTemp1,
            restTemp2: restTemp2,
            waterVolume2: this['waterVolume2'],
            restTemp3: restTemp3,
            waterVolume3: this['waterVolume3'],
            restTemp4: restTemp4,
            waterVolume4: this['waterVolume4'],
            restTemp5: restTemp5,
            waterVolume5: this['waterVolume5'],
        });
    }
    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value * 1;
        const cookies = new Cookies();
        let ret;
        if (name === 'grainWeight') {
            cookies.set('infusion.grainWeight', value);
            ret = this.calculateInfusion(value, this.state.grainTemp, this.state.waterGrainRatio, this.state.restTemp1, this.state.restTemp2, this.state.restTemp3, this.state.restTemp4, this.state.restTemp5);
        } else if (name === 'grainTemp') {
            cookies.set('infusion.grainTemp', value);
            ret = this.calculateInfusion(this.state.grainWeight, value, this.state.waterGrainRatio, this.state.restTemp1, this.state.restTemp2, this.state.restTemp3, this.state.restTemp4, this.state.restTemp5);
        } else if (name === 'waterGrainRatio') {
            cookies.set('infusion.waterGrainRatio', value);
            ret = this.calculateInfusion(this.state.grainWeight, this.state.grainTemp, value, this.state.restTemp1, this.state.restTemp2, this.state.restTemp3, this.state.restTemp4, this.state.restTemp5);
        } else if (name === 'restTemp1') {
            cookies.set('infusion.restTemp1', value);
            ret = this.calculateInfusion(this.state.grainWeight, this.state.grainTemp, this.state.waterGrainRatio, value, this.state.restTemp2, this.state.restTemp3, this.state.restTemp4, this.state.restTemp5);
        } else if (name === 'restTemp2') {
            cookies.set('infusion.restTemp2', value);
            ret = this.calculateInfusion(this.state.grainWeight, this.state.grainTemp, this.state.waterGrainRatio, this.state.restTemp1, value, this.state.restTemp3, this.state.restTemp4, this.state.restTemp5);
        } else if (name === 'restTemp3') {
            cookies.set('infusion.restTemp3', value);
            ret = this.calculateInfusion(this.state.grainWeight, this.state.grainTemp, this.state.waterGrainRatio, this.state.restTemp1, this.state.restTemp2, value, this.state.restTemp4, this.state.restTemp5);
        } else if (name === 'restTemp4') {
            cookies.set('infusion.restTemp4', value);
            ret = this.calculateInfusion(this.state.grainWeight, this.state.grainTemp, this.state.waterGrainRatio, this.state.restTemp1, this.state.restTemp2, this.state.restTemp3, value, this.state.restTemp5);
        } else if (name === 'restTemp5') {
            cookies.set('infusion.restTemp5', value);
            ret = this.calculateInfusion(this.state.grainWeight, this.state.grainTemp, this.state.waterGrainRatio, this.state.restTemp1, this.state.restTemp2, this.state.restTemp3, this.state.restTemp4, value);
        }

        this.setState(ret);


    }
    render() {

        return (
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Kalkulator wody do infuzji">
                            <p>
                                Kalkulator zacierania infuzyjnego z przerwami. Użyj tego kalkulatora, aby sprawdzić temperaturę i objętość wody do zacierania, a jeśli wykonujesz dodatkowe przerwy, możesz je zaplanować z wyprzedzeniem.
                            </p>
                            <p>
                                Kalkulator zakłada, że temperatura wrzenia wody to 100 °C i nie bierze pod uwagę tego, że kadź zaciernia odbiera również ciepło. Jeśli robisz infuzję wieloetapową, pamiętaj, że temperatura zacieru nieznacznie spadnie podczas przerwy i może być konieczna kompensacja.
                            </p>
                        </CollasableCard>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <Card title="Kalkulator wody do infuzji">
                            <InputField name="grainWeight" label="Waga słodu" step="0.1" value={this.state.grainWeight} unit="kg" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="grainTemp" label="Temperatura słodu" step="0.1" value={this.state.grainTemp} unit="°C" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="waterGrainRatio" label="Stosunek wody do słodu" step="0.1" value={this.state.waterGrainRatio} unit="litry/kg" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="restTemp1" label="Temperatura pierwszej przerwy" step="0.1" value={this.state.restTemp1} unit="°C" onChange={(event) => this.handleOnChange(event)} />

                            <table className="table table-striped table-responsive">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Krok</th>
                                        <th>Docelowa temperatura °C</th>
                                        <th>Potrzebna woda</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>{this.state.restTemp1}</td>
                                        <td>{this.state.waterVolume1} l @ {this.state.waterTemp1} °C</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td><InputField name="restTemp2" step="0.1" value={this.state.restTemp2} onChange={(event) => this.handleOnChange(event)} /></td>
                                        <td>{this.state.waterVolume2} l @ 100 °C</td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td><InputField name="restTemp3" step="0.1" value={this.state.restTemp3} onChange={(event) => this.handleOnChange(event)} /></td>
                                        <td>{this.state.waterVolume3} l @ 100 °C</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td><InputField name="restTemp4" step="0.1" value={this.state.restTemp4} onChange={(event) => this.handleOnChange(event)} /></td>
                                        <td>{this.state.waterVolume4} l @ 100 °C</td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td><InputField name="restTemp5" step="0.1" value={this.state.restTemp5} onChange={(event) => this.handleOnChange(event)} /></td>
                                        <td>{this.state.waterVolume5} l @ 100 °C</td>
                                    </tr>
                                </tbody>
                            </table>

                        </Card>
                    </div>
                </div>

            </div>
        );
    }
};

export default Infusion;