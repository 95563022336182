import React, { Component } from 'react';

class ColorIndicator extends Component {

    render() {

        return (
            <div className="form-group">
                <div className="px-3 py-3" style={{backgroundColor: `rgb(${this.props.color})`}}> 
                </div>
            </div>
        );
    }
}

export default ColorIndicator;