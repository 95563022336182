import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import InputField from './InputField';
import Card, { CollasableCard } from './Cards';

class Dilution extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();

        let ret = this.calculateDilution(
            cookies.get('dilution.beerVolume') || 20,
            cookies.get('dilution.originalGravity') || 16,
            cookies.get('dilution.desiredGravity') || 14);

        this.state = ret;

    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara - Korekta gęstości brzeczki";
    }
    calculateDilution(beerVolume, originalGravity, desiredGravity) {

        let waterSugarAddition = 'Dodaj wody';
        let waterSugarAmount;
        let waterSugarUnit = 'L';
        let DMEAddition = false;
        let DMEAmount;

        if (beerVolume > 0 && originalGravity > 0 && desiredGravity > 0) {

            if (desiredGravity < originalGravity) {
                const addedWater = ((originalGravity - desiredGravity) / desiredGravity) * beerVolume;
                waterSugarAmount = addedWater.toFixed(2);
            } else {
                const addedSugar = (desiredGravity - originalGravity) * beerVolume * 11;
                waterSugarAddition = 'Dodaj cukru lub suchego ekstraktu';
                waterSugarAmount = addedSugar.toFixed(2);
                waterSugarUnit = 'g';
                DMEAddition = true;
                DMEAmount = (addedSugar / 0.8).toFixed(2);
            }
        } else {
            waterSugarAddition = '';
            waterSugarAmount = 0;
            waterSugarUnit = '';
            DMEAddition = false;
        }


        return ({
            beerVolume: beerVolume,
            originalGravity: originalGravity,
            desiredGravity: desiredGravity,
            waterSugarAddition: waterSugarAddition,
            waterSugarAmount: waterSugarAmount,
            waterSugarUnit: waterSugarUnit,
            DMEAddition: DMEAddition,
            DMEAmount: DMEAmount,
        });
    }
    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value * 1;
        const cookies = new Cookies();
        let ret;
        if (name === 'beerVolume') {
            cookies.set('dilution.beerVolume', value);
            ret = this.calculateDilution(value, this.state.originalGravity, this.state.desiredGravity);
        } else if (name === 'originalGravity') {
            cookies.set('dilution.originalGravity', value);
            ret = this.calculateDilution(this.state.beerVolume, value, this.state.desiredGravity);
        } else {
            cookies.set('dilution.desiredGravity', value);
            ret = this.calculateDilution(this.state.beerVolume, this.state.originalGravity, value);
        }

        this.setState(ret);


    }
    render() {
        let da;
        if (this.state.DMEAddition) {
            da = <InputField label="lub ekstraktu płynnego" value={this.state.DMEAmount} unit="g" readOnly />
        }

        return (
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Korekcja gęstości brzeczki">
                            <p>
                                Ilość brzeczki się zgadza ale ekstrakt nie? Możesz to skorygować (oczywiście w rozsądnych granicach) poprzez dodanie wody (jeśli brzeczka jest za gęsta) lub cukru lub ekstraktu jeśli brzeczka jest "za cieńka".
                            </p>
                            <p>
                                Do rozcieńczania można użyć przegotowanej i wystudzonej wody z kranu, można także dolać świeżej wody mineralnej, a niektórzy ryzykanci pozwalają sobie nawet na dolewanie surowej kranówki.
                            </p>
                        </CollasableCard>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <Card title="Korekta gęstości brzeczki">
                            <InputField name="beerVolume" label="Ilość brzeczki" step="0.1" value={this.state.beerVolume} unit="L" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="originalGravity" label="Zmierzona gęstość" step="0.1" value={this.state.originalGravity} unit="BLG" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="desiredGravity" label="Oczekiwana gęstość" step="0.1" value={this.state.desiredGravity} unit="BLG" onChange={(event) => this.handleOnChange(event)} />
                            <InputField label={this.state.waterSugarAddition} value={this.state.waterSugarAmount} unit={this.state.waterSugarUnit} readOnly />

                            {da}
                        </Card>
                    </div>
                </div>

            </div>
        );
    }
};

export default Dilution;