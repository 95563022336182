import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Converters from './Converters';
import InputField from './InputField';
import {CollasableCard, Card} from './Cards';

class Alcohol extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();

        let ret = this.calculateAlcohol(cookies.get('alcohol.originalGravity') || 13, cookies.get('alcohol.finalGravity') || 3.6);

        this.state = ret;

    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara - Obliczanie zawartości alkoholu w gotowym piwie";
    }
    calculateAlcohol(og, fg) {

        let _fg = Converters.blgToGravity(fg);
        let _og = Converters.blgToGravity(og);

        let abv = (76.08 * (_og - _fg) / (1.775 - _og)) * (_fg / 0.794);
        let abw = abv * 0.78924;
        let att = 100 * (_og - _fg) / (_og - 1);
        let cal = (1881.22 * _fg * (_og - _fg) / (1.775 - _og) + 3550 * _fg * ((.1808 * _og) + (.8192 * _fg) - 1.0004)) / 0.354882355;

        if (abv < 0) {
            abv = "NaN";
            abw = "NaN";
            att = "NaN";
            cal = "NaN";
        } else {
            abv = abv.toFixed(1);
            abw = abw.toFixed(1);
            att = att.toFixed(1);
            cal = cal.toFixed(1);
        }


        return ({
            og: og,
            fg: fg,
            abv: abv,
            abw: abw,
            att: att,
            cal: cal,
        });
    }
    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const cookies = new Cookies();
        let ret;
        if (name === 'og') {
            cookies.set('alcohol.originalGravity', value);
            ret = this.calculateAlcohol(value, this.state.fg);
        } else {
            cookies.set('alcohol.finalGravity', value);
            ret = this.calculateAlcohol(this.state.og, value);
        }

        this.setState(ret);


    }
    render() {
        return (
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Obliczanie potencjalnej zawartości alkoholu w piwie">
                            <p>
                                Obliczenie zawartości alkoholu w piwie odbywa się w oparciu o zmianę gęstości/ekstraktu brzeczki/piwa. Należy podać gęstość
                                przed zadaniem drożdży a następnie po fermentacji (przed dodaniem cukru do refermentacji).
                            </p>
                            <p>
                                Dodatek surowca do refermentacji do butelki zwiększa zawartość alkoholu o ok. 0,4%-0,5%
                            </p>
                        </CollasableCard>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <Card title="Potencjalna zawartość alkoholu">
                            <InputField name="og" label="Ekstrakt nastawny" step="0.1" value={this.state.og} unit="BLG" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="fg" label="Ekstrakt po fermentacji" step="0.1" value={this.state.fg} unit="BLG" onChange={(event) => this.handleOnChange(event)} />

                            <InputField label="% alkoholu w piwie (objętościowo ABV)" value={this.state.abv} unit="%" readOnly />
                            <InputField label="% alkoholu w piwie (wagowo ABW)" value={this.state.abw} unit="%" readOnly />
                            <InputField label="Stopień odfermentowania" value={this.state.att} unit="%" readOnly />
                            <InputField label="Kalorie" value={this.state.cal} unit="kcal/l" readOnly />
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
};

export default Alcohol;