class Converters {
    static blgToGravity(blg) {
        return ((blg / (258.6 - ((blg / 258.2) * 227.1))) + 1);
    }

    static gravityToPlato(sg) {
        const plato = (-1 * 616.868) + (1111.14 * sg) - (630.272 * Math.pow(sg, 2)) + (135.997 * Math.pow(sg, 3));
        return plato;
    }
}

export default Converters;