import React, { Component } from 'react';
import Converters from './Converters';
import InputField from './InputField';
import Card from './Cards';
class Gravity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blg: 12,
            sg: 1.048,
        };

    }
    componentDidMount() {
        document.title = "Kalkulator piwowara - Konwerter gęstości BLG/Plato na SG";
    }

    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        console.log(name);
        console.log(value);
        let ret = {};

        if (name === "blg") {
            ret['sg'] = Converters.blgToGravity(value).toFixed(3);
        } else {
            ret['blg'] = Converters.gravityToPlato(value).toFixed(1);
        }

        ret[name] = value;

        console.log(ret);

        this.setState(ret);
    }
    render() {
        return (
            <div className="col-sm-6">
                <Card title="Konwerter jednostek gęstości">
                    <InputField name="blg" label="BLG" step="0.01" value={this.state.blg} unit="BLG" onChange={(event) => this.handleOnChange(event)} />
                    <InputField name="sg" label="SG (Specific Gravity)" step="0.01" value={this.state.sg} unit="SG" onChange={(event) => this.handleOnChange(event)} />
                </Card>
            </div>
        );
    };
};

export default Gravity;