import React, { Component } from 'react';

class BeerCarbonation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            isLoaded: false,
            error: null
        };
    }
    componentDidMount() {
        fetch("/api/beerstyles")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        data: result.data,
                        filteredData: result.data
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    handleOnChange(event) {
        const value = event.target.value;

        const filteredData = this.state.data.filter(function (el) {
            return el.name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
          });

        this.setState({
            filteredData : filteredData,
        })
        

          
    }

    render() {
        const { error, isLoaded, filteredData } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {

            return (
                <div>
                    <div className="table-responsive">
                        <table className="table table-hover table-condensed table-striped table-responsive">

                            <thead className="thead-default">
                                <tr>
                                    <th>Nazwa stylu</th>
                                    <th>Nagazowanie</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="2">
                                        <input onChange={(event) => this.handleOnChange(event)} />
                                    </td>
                                </tr>
                                {filteredData.map(item => (
                                    <tr >
                                        <td>{item.name}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            );
        }
    }
}

export default BeerCarbonation;