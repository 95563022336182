import React, { Component } from 'react';
import Card from'./Cards';

class Contact extends Component {
    render() {
        return (
            <div className="col-sm-12">
                <Card title="Kontakt">
                    Masz pytania, sugestie, uwagi? <br />
                    Nie wahaj się napisać do nas: <a href="mailto:kontakt@kalkulatorpiwowara.pl">kontakt@kalkulatorpiwowara.pl</a>
                </Card>
            </div>
        );
    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara - Kontakt";
    }
}

export default Contact;