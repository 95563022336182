import React, { Component } from 'react';

import InputField from './InputField';
import Card from './Cards';
class Volume extends Component {
    constructor(props) {
        super(props);
        this.coeffs = {
            liter: 1, mililiter: 1000, hectoliter: 1 / 100,
            cubicMeter: 1 / 1000, cubicDecimeter: 1, cubicCentimeter: 1000,
            pintUs: 2.113376419, quartUs: 1.056688209, galUs: 0.264172052,
            pint: 1.759753986, quart: 0.879876993, gal: 0.219969248,
            tspKan: 211.170478367, tspUs: 202.88413616, tsp: 200, tspBr: 168.936382694,
            tbspKan: 70.390159456, tbspUs: 67.628045398, tbsp: 66.666666667, tbspBr: 56.312127565,
            cKan: 4.399384966, cUs: 4.226752838, c: 4, cBr: 3.519507973
        };
        this.state = this.coeffs;

    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara - Konwerter jednostek objętości";
    }

    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        const liters = (value / this.coeffs[name]);

        let ret = {};

        for (const u in this.coeffs) {
            ret[u] = (liters * this.coeffs[u]).toFixed(3);
        }

        delete ret[name];
        ret[name] = value;

        this.setState(ret);
    }
    render() {
        return (
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-3">
                        <Card title="SI">
                            <InputField name="cubicMeter" label="Metr sześcienny" step="0.1" value={this.state.cubicMeter} unit="m³" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="cubicDecimeter" label="Decymetr sześcienny" step="0.1" value={this.state.cubicDecimeter} unit="dm³" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="cubicCentimeter" label="Centymetr sześcienny" step="0.1" value={this.state.cubicCentimeter} unit="cm³" onChange={(event) => this.handleOnChange(event)} />
                        </Card>
                    </div>
                    <div className="col-sm-3">
                        <Card title="Metryczny (litry)">
                            <InputField name="hectoliter" label="Hektolitr" step="0.1" value={this.state.hectoliter} unit="hl" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="liter" label="Litr" step="0.1" value={this.state.liter} unit="l" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="mililiter" label="Mililitr" step="0.1" value={this.state.mililiter} unit="ml" onChange={(event) => this.handleOnChange(event)} />
                        </Card>
                    </div>

                    <div className="col-sm-3">
                        <Card title="Amerykańska miara płynów (US Liquid)">
                            <InputField name="pintUs" label="Pinta" step="0.1" value={this.state.pintUs} unit="pt" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="quartUs" label="Kwarta" step="0.1" value={this.state.quartUs} unit="qt" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="galUs" label="Galon" step="0.1" value={this.state.galUs} unit="gal" onChange={(event) => this.handleOnChange(event)} />
                        </Card>
                    </div>

                    <div className="col-sm-3">
                        <Card title="Brytyjski">
                            <InputField name="pint" label="Pinta" step="0.1" value={this.state.pint} unit="pt" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="quart" label="Kwarta" step="0.1" value={this.state.quart} unit="qt" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="gal" label="Galon" step="0.1" value={this.state.gal} unit="gal" onChange={(event) => this.handleOnChange(event)} />
                        </Card>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <Card title="Jednostki kuchenne">
                            <div className="row">
                                <div className="col-sm-4">
                                    <InputField name="tspKan" label="Łyżeczka (kanadyjska)" step="0.1" value={this.state.tspKan} unit="tsp" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="tspUs" label="Łyżeczka (US)" step="0.1" value={this.state.tspUs} unit="tsp" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="tsp" label="Łyżeczka (Metryczna)" step="0.1" value={this.state.tsp} unit="tsp" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="tspBr" label="Łyżeczka (Brytyjska)" step="0.1" value={this.state.tspBr} unit="tsp" onChange={(event) => this.handleOnChange(event)} />
                                </div>
                                <div className="col-sm-4">
                                    <InputField name="tbspKan" label="Łyżka (kanadyjska)" step="0.1" value={this.state.tbspKan} unit="tbsp" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="tbspUs" label="Łyżka (US)" step="0.1" value={this.state.tbspUs} unit="tbsp" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="tbsp" label="Łyżka (Metryczna)" step="0.1" value={this.state.tbsp} unit="tbsp" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="tbspBr" label="Łyżka (Brytyjska)" step="0.1" value={this.state.tbspBr} unit="tbsp" onChange={(event) => this.handleOnChange(event)} />
                                </div>

                                <div className="col-sm-4">
                                    <InputField name="cKan" label="Filiżanka (kanadyjska)" step="0.1" value={this.state.cKan} unit="c" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="cUs" label="Filiżanka (US)" step="0.1" value={this.state.cUs} unit="c" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="c" label="Filiżanka (Metryczna)" step="0.1" value={this.state.c} unit="c" onChange={(event) => this.handleOnChange(event)} />
                                    <InputField name="cBr" label="Filiżanka (Brytyjska)" step="0.1" value={this.state.cBr} unit="c" onChange={(event) => this.handleOnChange(event)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        );
    };
};

export default Volume;