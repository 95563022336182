import React, { Component } from 'react';

import InputField from './InputField';
import Card from './Cards';

class Weight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gr: 100,
            kg: 0.1,
            oz: 3.52739619,
            lb: 0.220462262,
        };

    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara - Konwerter jednostek wag";
    }

    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const coeffs = {
            gr: 1,
            kg: 1 / 1000,
            oz: 0.0352739619,
            lb: 0.00220462262
        };

        const gr = (value / coeffs[name]);
        const kg = gr * coeffs['kg'];
        const oz = gr * coeffs['oz'];
        const lb = gr * coeffs['lb'];

        let ret = {
            gr: gr.toFixed(1),
            kg: kg.toFixed(3),
            oz: oz.toFixed(3),
            lb: lb.toFixed(3),
        };

        delete ret[name];
        ret[name] = value;

        this.setState(ret);
    }
    render() {
        return (
            <div className="col-sm-6">
                <Card title="Konwerter jednostek wagi">
                    <InputField name="gr" label="Gramy" step="0.1" value={this.state.gr} unit="g" onChange={(event) => this.handleOnChange(event)} />
                    <InputField name="kg" label="Kilogramy" step="0.1" value={this.state.kg} unit="kg" onChange={(event) => this.handleOnChange(event)} />
                    <InputField name="oz" label="Uncje" step="0.1" value={this.state.oz} unit="oz" onChange={(event) => this.handleOnChange(event)} />
                    <InputField name="lb" label="Funty" step="0.1" value={this.state.lb} unit="lb" onChange={(event) => this.handleOnChange(event)} />
                </Card>
            </div>
        );
    }
};

export default Weight;