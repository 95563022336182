import React, { Component } from 'react';

class HoverButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
        };
        this.handleHover = this.handleHover.bind(this);
    }

    handleHover() {
        this.setState(prevState => (
            {
                isHovered: !prevState.isHovered
            }
        ));
    }

    render() {

        const iconClass = this.state.isHovered ? "fas fa-fw " + this.props.hoveredIcon : "fas fa-fw " + this.props.normalIcon;
        const btnClass = "btn " + (this.props.btnClass ? this.props.btnClass : "btn-primary");
        return (
            <button onClick={this.props.onClick} onMouseOver={this.handleHover} onMouseOut={this.handleHover} type="button" className={btnClass}><i className={iconClass}></i> {this.props.text}</button>
        );
    }
}

export default HoverButton;