import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import InputField from './InputField';
import BeerCarbonation from './BeerCarbonation';

import Card, { CollasableCard } from './Cards';

class Carbonation extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();

        let ret = this.calcCarbonation(
            cookies.get('carbonation.beerVolume') || 20,
            cookies.get('carbonation.CO2Level') || 2,
            cookies.get('carbonation.beerTemp') || 18,
            cookies.get('carbonation.sugarType') || 'CORN',
            cookies.get('carbonation.orginalGravity') || 12
        );

        this.state = ret;

    }
    componentDidMount() {
        document.title = "Kalkulator Piwowara - Nagazowanie piwa - cukier do refermentacji";
    }

    calcCarbonation(beerVolume, CO2Level, beerTemp, sugarType, orginalGravity) {

        const p = ((beerTemp * (9 / 5) + 32));

        const greenBeerCo2 = 3.0378 - (0.050062 * p) + (0.00026555 * Math.pow(p, 2));
        const carbDiff = CO2Level - greenBeerCo2;
        let n = carbDiff * beerVolume;

        const carbonationFactor = {
            'TABLE': 3.82,
            'CORN': 4.02,
            'DME': 6.8,
            'HONEY': 4.55,
            'GOZDAWA': 5.05287
        };

        n *= carbonationFactor[sugarType];

        let sugarAmount;
        let waterAmount;

        if (n > 0) {
            sugarAmount = n.toFixed(0);
            waterAmount = (n * 100 / orginalGravity - n).toFixed(0);
        }


        return ({
            beerVolume: beerVolume,
            CO2Level: CO2Level,
            beerTemp: beerTemp,
            sugarType: sugarType,
            orginalGravity: orginalGravity,
            sugarAmount: sugarAmount,
            waterAmount: waterAmount,
        });
    }

    handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const cookies = new Cookies();
        let ret;
        if (name === 'beerVolume') {
            cookies.set('carbonation.beerVolume', value);
            ret = this.calcCarbonation(value, this.state.CO2Level, this.state.beerTemp, this.state.sugarType, this.state.orginalGravity);
        } else if (name === 'CO2Level') {
            cookies.set('carbonation.CO2Level', value);
            ret = this.calcCarbonation(this.state.beerVolume, value, this.state.beerTemp, this.state.sugarType, this.state.orginalGravity);
        } else if (name === 'beerTemp') {
            cookies.set('carbonation.beerTemp', value);
            ret = this.calcCarbonation(this.state.beerVolume, this.state.CO2Level, value, this.state.sugarType, this.state.orginalGravity);
        } else if (name === 'sugarType') {
            cookies.set('carbonation.sugarType', value);
            ret = this.calcCarbonation(this.state.beerVolume, this.state.CO2Level, this.state.beerTemp, value, this.state.orginalGravity);
        } else if (name === 'orginalGravity') {
            cookies.set('carbonation.orginalGravity', value);
            ret = this.calcCarbonation(this.state.beerVolume, this.state.CO2Level, this.state.beerTemp, this.state.sugarType, value);
        }

        this.setState(ret);


    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <CollasableCard title="Obliczenie ilości surowca do refermentacji">
                            <p>
                                W przypadku rozlewu do butelek konieczne jest dodanie do piwa surowca do refermentacji, najczęściej jest to nieco glukozy
                                lub innego cukru, który pozwala na wznowienie fermentacji i nagazowanie piwa CO<sub>2</sub> pochodzącym z fermentacji.
                            </p>
                            <div>
                                Ilość dodawanego surowca zeleży od:
                                <ul>
                                    <li>
                                        stylu piwa czyli stopnia nagazowania jaki chcemy uzyskać. Zalecane dla danego stylu nagazowanie podaje się w jednostkach
                                        volume (vol) np. 2 vol oznacza, że w 1 litrze piwa jest 2 litry CO<sub>2</sub>. Nagazowanie waha się od 1 vol dla piw angielskich
                                        (prawie bez gazu) do nawet 4 vol dla pszenicznych (piwo samo wychodzi z butelki)
                                    </li>
                                    <li>
                                        temperatury w której przeprowadzamy rozlew - im cieplej tym więcej rozpuszczonego w piwie CO<sub>2</sub> ucieka, w 13*C w piwie pozostaje
                                        1.1 vol, w 21*C tylko 0.8 vol. Dlatego do lagerów dodajemy nieco mniej surowca
                                    </li>
                                    <li>
                                        rodzaju surowca - każdy użyty surowiec ma inną fermentowalność: do nagazowania 20L piwa do 2.5 vol w 20*C potrzeba: 132g
                                        glukozy, 125 g sacharozy, 223 g suchego ekstraktu, 149g miodu
                                    </li>
                                    <li>
                                        objętości piwa do nagazowania
                                    </li>
                                </ul>
                            </div>
                        </CollasableCard>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-6">
                        <Card title="Nagazowanie">
                            <InputField name="beerVolume" label="Ilość piwa" step="0.1" value={this.state.beerVolume} unit="L" onChange={(event) => this.handleOnChange(event)} />
                            <InputField name="CO2Level" label="Poziom CO<sub>2</sub>" step="0.1" value={this.state.CO2Level} onChange={(event) => this.handleOnChange(event)} />

                            <div className="form-group">
                                <label className="control-label font-weight-bold">Cukier do refermentacji</label>
                                <select className="form-control" name="sugarType" value={this.state.sugarType} onChange={(event) => this.handleOnChange(event)}>
                                    <option value="CORN">Glukoza</option>
                                    <option value="TABLE">Cukier biały</option>
                                    <option value="DME">Suchy ekstrakt</option>
                                    <option value="HONEY">Miód</option>
                                    <option value="GOZDAWA">Polepszacz piwa domowego Gozdawa</option>
                                </select>
                            </div>

                            <InputField name="beerTemp" label="Temperatura piwa" step="0.1" value={this.state.beerTemp} unit="°C" onChange={(event) => this.handleOnChange(event)} />

                            <InputField label="Ilość cukru do refermentacji" value={this.state.sugarAmount} unit="g" readOnly />

                            <InputField name="orginalGravity" label="Ekstrakt początkowy" step="0.1" value={this.state.orginalGravity} unit="BLG" onChange={(event) => this.handleOnChange(event)} />

                            <InputField label="Zalecana ilość wody do przygotowania syropu cukrowego" value={this.state.waterAmount} unit="ml" readOnly />
                        </Card>

                    </div>

                    <div className="col-sm-6">
                        <Card title="Poziomy nagazowania CO<sub>2</sub>">
                            <BeerCarbonation />
                        </Card>

                    </div>
                </div>

            </div>
        );
    }
};

export default Carbonation;